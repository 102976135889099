footer {
  padding: 55px 0 0 0;
  background: #f5f5f5;
  margin-top: 90px;
  line-height: 1.8;
  ul {
    list-style: none;
    padding: 0;
    li {
      font-size: 14px;
      font-weight: 500;
      a {
        font-size: 14px;
        color: #000;
        font-weight: 500;
        &:hover {
          color: #0f73b5;
        }
      }
      &.footer-address {
        margin-top: 30px;
      }
      &.footer-subscription {
        margin-top: 30px;
        position: relative;
        input {
          border: 0;
          outline: none;
        }
        input[type="email"] {
          width: 100%;
          position: relative;
          background: #fff;
          padding: 0 34px 0 10px;
          height: 40px;
        }
        input[type="submit"] {
          background: #0a3b5c url(../../production/images/email.svg) no-repeat center center;
          background-size: 14px;
          height: 34px;
          width: 34px;
          position: absolute;
          right: 3px;
          top: 50%;
          transform: translateY(-50%);
					text-indent:-9999px;
        }
      }
    }
    &.without-title {
      margin-top: 32px;
    }
  }
}

.scroll-to-top {
  position: fixed;
  right: 40px;
  bottom: 40px;
  width: 40px;
  height: 40px;
  background: url(../../production/images/totop.png) no-repeat 0 0;
  background-size: 100%;
}

body.index {
  footer {
    margin-top: 0;
  }
}
