section.contacts {
  margin-bottom: 70px;
  .contacts-info {
    .contact-block {
      padding: 18px 0;
      line-height: 2;
      border-bottom: 1px solid #e5e8eb;
      font-size: 16px;
      i {
        color: rgba(34, 154, 200, 1);
        width: 43px;
        height: 43px;
        margin-right: 12px;
        border: 1px solid #e5e8eb;
        border-radius: 50%;
        text-align: center;
        line-height: 43px;
        font-size: 20px;
      }
      .contact-block span {
        font-size: 24px;
      }
      .address p {
        margin-left: 55px;
        margin-bottom: 0;
      }
    }
  }

  .contacts-feedback {
    .feedback-block-inner {
      background: rgba(34, 154, 200, 0.2);
      border-radius: 4px;
      padding: 34px;
      display: inline-block;
      width: 100%;
      @include media-breakpoint-down(sm) {
        width: auto;
        max-width: 570px;
        margin: 0 auto;
      }
      input[type="text"],
      input[type="email"],
      textarea {
        border: 1px solid #ccc;
        height: 44px;
        width: 100%;
        margin-bottom: 14px;
        line-height: 44px;
        padding: 0 10px 0 20px;
        display: block;
      }
      textarea {
        height: 80px;
      }
      label {
        font-size: 15px;
        color: #454545;
        font-weight: 600;
        span {
          color: #fa2837;
        }
      }
    }
  }
}
