header {
  background: url("../../production/images/back-header.jpg") no-repeat 0 0;
  height: 147px;
  color: #fff;
  @include media-breakpoint-down(sm) {
    height: auto;
    background: url("../../production/images/back-header.jpg") no-repeat 0 0;
    background-size: cover;
  }

  .mobile-menu-trigger {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    background: url(../../production/images/icon-menu.png) no-repeat 0 0;
    background-size: contain;
    z-index: 102;
    display: none;
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
  .mobile-menu-wrapper {
    padding-top: 70px;
    padding-bottom: 50px;
    z-index: 103;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    display: none;
    .close {
      background: url(../../production/images/icon-close.png) no-repeat 0 0;
      background-size: contain;
      position: absolute;
      top: 20px;
      right: 20px;
      width: 30px;
      height: 30px;
    }
    ul.mobile-menu {
      padding: 0;
      margin: 0;
      li {
        // width: calc(100% - 60px);
        border-top: 1px solid rgba(3, 57, 94, 0.2);
        a {
          display: block;
          padding: 12px 0 12px 30px;
          color: #0a3b5c;
          font-weight: 600;
          &:hover {
            background: #0a3b5c;
            color: #fff;
          }
        }
      }
    }
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
  .col-md-3,
  .col-md-6 {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(sm) {
      justify-content: center;
      margin: 15px 0;
    }
  }
  .logo {
  }
  .header-top {
    height: 92px;
    @include media-breakpoint-down(sm) {
      height: auto;
    }
    .row {
      height: 100%;
    }
    .search {
      position: relative;
      @include media-breakpoint-down(lg) {
        margin-left: 20px;
      }

      input[type="text"] {
        background: transparent;
        border: solid 1px #65666a;
        height: 45px;
        line-height: 45px;
        padding-left: 48px;
        min-width: 257px;
        color: #fff;
        outline: none;
        @include media-breakpoint-down(lg) {
          min-width: auto;
          height: 35px;
          line-height: 35px;
        }
        @include media-breakpoint-down(sm) {
          margin: 0;
        }
      }
      input[type="submit"] {
        @include verticalAlignCenter();
        display: flex;
        left: 0px;
        padding: 0;
        background: none;
        border: 0;
        background: url(../../production/images/icon-search.png) no-repeat center center;
        background-size: 18px;
        width: 45px;
        height: 45px;
      }
    }
    .phones {
      text-align: right;
      display: inline-block;
      color: #c9c9c9;
      .phone {
        font-size: 13px;
        line-height: 1.5;
        letter-spacing: 0.78px;
        padding-left: 30px;
        background: url(../../production/images/phone-icon.png) no-repeat center left;
        background-size: 16px;
        b {
          font-size: 17px;
          font-weight: bold;
          margin-left: 7px;
          color: #fff;
        }
        // &.a1 {
        //   background: url(../images/phone-icon.png) no-repeat center left;
        //   background-size: 16px;
        // }
        // &.mts {
        //   background: url(../images/icon-mts.svg) no-repeat center left;
        //   background-size: 16px;
        // }
      }
      span {
        opacity: 0.6;
        display: block;
        text-align: left;
        font-size: 14px;
      }
    }
  }
  .header-bottom {
    height: 54px;

    ul.menu {
      padding: 0;
      display: flex;
      justify-content: flex-start;
      border-top: 1px solid #4e5960;
      padding-top: 13px;
      @include media-breakpoint-down(lg) {
        justify-content: space-between;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
      li {
        margin-right: 60px;
        display: inline-block;
        @include media-breakpoint-down(lg) {
          margin-right: 0px;
        }

        a {
          color: #fff;
          &:hover,
          &.active {
            color: #c8c8c8;
          }
        }
      }
    }
  }
}

body.index {
  header {
    background: transparent;
    position: absolute;
    width: 100%;
    z-index: 90;
  }
}
