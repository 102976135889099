h1 {
  font-size: 38px;
  color: #000;
  text-transform: uppercase;
  font-weight: 500;
  @include media-breakpoint-down(sm) {
    font-size: 28px;
  }
}
h2 {
  font-size: 36px;
  color: #000;
  text-transform: uppercase;
  font-weight: 500;
  @include media-breakpoint-down(sm) {
    font-size: 26px;
  }
}
h3 {
  font-size: 22px;
  color: #000;
  line-height: 1.18;
  margin-bottom: 24px;
}
h4 {
  line-height: 1.49;
  letter-spacing: 0.77px;
  font-size: 14px;
  color: #000000;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 11px;
}

.text-right {
  text-align: right;
  justify-content: flex-end;
}
.link-fixed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.bold {
  font-weight: bold;
}

body {
  font-family: "Roboto";
}

.background-grey {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.goback-link {
  display: block;
  font-size: 16px;
  color: #000;
  padding-left: 34px;
  position: relative;
  margin-top: 62px;
  &::before {
    content: "";
    background: url(../../production/images/arrow-left.svg) no-repeat left center;
    width: 34px;
    height: 19px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
  }
}

.btn {
  padding: 6px 15px;
  background: #0a3b5c;
  color: #fff;
  &:hover {
    background: #229ac8;
    color: #fff;
  }
  &.btn-right {
    float: right;
  }
}

.paging {
  a {
    padding: 7px 12px;
    background: #0a3b5c;
    color: #fff;
    &:hover {
      background: #229ac8;
      text-decoration: none;
    }
  }

  span {
    padding: 7px 12px;
    background: #f5f5f5;
    color: #0a3b5c;
  }
}

section.catalog-description{
	h3{
		margin-top:30px;
	}
}

ul.page-numbers{
	list-style:none;
	display:flex;
	margin-top:30px;
	padding:0;
	li{
		a, span{
			border: 2px solid #e0e0e0;
			line-height: 40px;
			width: 40px;
			height: 40px;
			text-align: center;
			display: inline-block;
			margin: 0 3px;
			color: #000;
			&.current, &:hover{
				border: 2px solid #b9b9b9;
				color: #00adef;
				text-decoration: none;
			}

		}
	}
}
