.clients-list {
  background: #f5f5f5;
  padding-bottom: 70px;
  margin-top: 96px;
  position: relative;
  @include media-breakpoint-up(xl) {
    padding-top: 0;
    padding-bottom: 0;
    height: 233px;
  }
	@include media-breakpoint-down(lg) {
		margin-top:6px;
	}

  .container {
    height: 100%;
    position: relative;
    .section-title {
      margin-bottom: 27px;
    }
    @include media-breakpoint-up(xl) {
      .section-title {
        &.square-title {
          background: url(../../production/images/back-square-title.jpg);
          width: 291px;
          height: 294px;
          padding: 75px 20px 0 20px;
          margin: 0;
          position: absolute;
          bottom: 0;
          h2 {
            line-height: 1.25;
            font-size: 32px;
            color: #fff;
            font-weight: 400;
          }
        }
      }
    }

    .clients-carousel {
      width: 100%;
      margin: 0 auto;
      @include media-breakpoint-up(xl) {
        float: right;
        width: calc(100% - 392px);
        padding-right: 60px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .owl-item {
				img{
					@include media-breakpoint-down(sm) {
						width: 100%;
						max-width: 200px;
						margin: 0 auto;
					}

				}
      }
      .owl-nav {
        width: 100%;
        button {
          outline: none;
          opacity: 0.5;

          &:hover {
            opacity: 1;
          }
					@include media-breakpoint-down(lg) {
						width: 37px;
						height: 100%;
						border-radius: 5px;
						background: #ccc;
						padding: 0 8px !important;
						&:hover{
							opacity: 0.8;
						}
					}
					img{
						max-width:100%;
					}
        }

        .owl-prev {
          position: absolute;
          top: 50%;
          @include media-breakpoint-up(xl) {
            left: -60px;
          }
          transform: translateY(-50%) rotate(180deg);
        }
        .owl-next {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
    }
    clients-carousel img {
      max-width: 125px;
      margin: 0 auto;
    }
  }
}
