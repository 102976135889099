#advantages-list {
  padding-top: 55px;
  .advantages {
    display: flex;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
    .advantage {
      text-align: center;
      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }
      @include media-breakpoint-only(md) {
        width: 33%;
      }
      @include media-breakpoint-only(sm) {
        width: 50%;
      }
			@include media-breakpoint-down(sm) {
				width: 100%;
				margin-bottom: 30px;
			}
      img {
        margin-bottom: 15px;
      }
      span {
        line-height: 1.25;
        font-size: 16px;
        display: block;
      }
    }
  }
}
