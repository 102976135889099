#main-slider {
  position: relative;
  padding-top: 0;
  .main-video {
    width: 100%;
    height: 392px;
    overflow: hidden;
    @include media-breakpoint-down(md) {
      height: auto;
    }
    @include media-breakpoint-up(xl) {
      height: 500px;
    }
    video {
      max-width: 100%;
      display: block;
      @include media-breakpoint-down(xs) {
        //min-height: 300px;
      }
    }
  }

  .main-slider-description {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 9999;
    background: rgba(255, 255, 255, 0.7);
    padding: 20px;
    border-radius: 6px;
    @include media-breakpoint-down(lg) {
      width: 80%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      background: rgba(255, 255, 255, 0.96);
      transform: none;
      border-radius: 0;
      padding: 30px 20px;
      @include shadow();
    }

    h2 {
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 20px;
      display: block;
    }
    hr {
      margin: 0 0 20px 0;
      width: 60px;
      height: 1px;
      background: #0a3b5c;
      display: block;
    }

    ul {
      padding: 0 0 0 20px;
      margin: 0;
    }
    p {
      margin: 0;
    }
    .btn {
      position: absolute;
      top: 20px;
      right: 20px;
      @include media-breakpoint-down(xs) {
        position: inherit;
        margin-top: 20px;
        top: auto;
        right: auto;
      }
    }
  }
}
