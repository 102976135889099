@import "sections/slider";
@import "sections/catalog-categories";
@import "sections/news-list";
@import "sections/clients";
@import "sections/advantages";
@import "sections/map";

section {
  padding: 45px 0 0 0;
  .section-title {
    margin-bottom: 47px;
    text-align: center;
    h2 {
      display: inline-block;
    }
  }
}

section.text-search {
  h3 {
    margin-bottom: 5px;
  }
  .entry-search {
    margin-bottom: 20px;
  }
}
