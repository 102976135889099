#map {
  padding-top: 0;
  .map-wrapper {
    height: 600px;
    width: 100%;
    background: #ccc;
    position: relative;
    @include media-breakpoint-down(lg) {
    }
		@include media-breakpoint-down(sm) {
			height: auto;
		}
    .map-contacts {
      position: absolute;
			top: 10px;
			left:10px;
      max-width: 462px;
      padding: 60px;
      background: #fff;
      z-index: 101;
      @include media-breakpoint-down(lg) {
        @include shadow();
      }
			@include media-breakpoint-down(sm) {
				position: initial;
				max-width: 100%;
			}
      @include media-breakpoint-down(xs) {
        padding: 30px;
      }
      h2 {
        text-transform: none;
        margin-bottom: 18px;
      }
      p {
        line-height: 1.67;
        color: #000;
        font-weight: 500;
        font-size: 18px;
        @include media-breakpoint-down(xs) {
          font-size: 14px;
        }
      }
    }
  }
}
