@font-face {
  font-family: "Roboto";
  src: url("../webfonts/Roboto-Regular.eot");
  src: url("../webfonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
    url("../webfonts/Roboto-Regular.woff") format("woff"),
    url("../webfonts/Roboto-Regular.ttf") format("truetype"),
    url("../webfonts/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../webfonts/Roboto-Light.eot");
  src: url("../webfonts/Roboto-Light.eot?#iefix") format("embedded-opentype"),
    url("../webfonts/Roboto-Light.woff") format("woff"),
    url("../webfonts/Roboto-Light.ttf") format("truetype"),
    url("../webfonts/Roboto-Light.svg#Roboto-Light") format("svg");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../webfonts/Roboto-Medium.eot");
  src: url("../webfonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"),
    url("../webfonts/Roboto-Medium.woff") format("woff"),
    url("../webfonts/Roboto-Medium.ttf") format("truetype"),
    url("../webfonts/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../webfonts/Roboto-Bold.eot");
  src: url("../webfonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"),
    url("../webfonts/Roboto-Bold.woff") format("woff"),
    url("../webfonts/Roboto-Bold.ttf") format("truetype"),
    url("../webfonts/Roboto-Bold.svg#Roboto-Light") format("svg");
  font-weight: bold;
  font-style: normal;
}
