#news-list {
  .news {
    background: url(../../production/images/arrow.svg) no-repeat;
    background-position: bottom 26px right 26px;
    padding: 0 0 75px 0;
    @include shadow();
    .news-wrapper {
      padding: 29px 28px 0 28px;
    }
    img {
      margin: 0;
      width: 100%;
    }
    h3 {
      color: #143e5b;
    }
    p {
      line-height: 1.5;
      font-size: 16px;
      opacity: 0.8;
      color: #000;
      font-weight: 500;
    }
  }
}
