#inner-products {
  .product {
    background: url(../../production/images/arrow.svg) no-repeat;
    background-position: top 26px right 26px;
    margin: 0 0 30px 0;
    padding: 15px 15px;
    text-align: center;
    @include shadow();
    @include media-breakpoint-down(sm) {
    }
    @include media-breakpoint-down(xs) {
    }
  }
  h3 {
    text-transform: none;
    font-size: 16px;
  }
  img {
    margin-bottom: 30px;
    max-width: 200px;
    @include media-breakpoint-down(sm) {
    }
  }
  p {
    line-height: 1.25;
    font-size: 16px;
    opacity: 0.5;
    color: #000;
    font-weight: 500;
    @include media-breakpoint-down(xs) {
    }
  }
}
