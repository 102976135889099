#inner-product {
  .product-title {
    margin-bottom: 38px;
    text-align: left;
  }
  .product-gallery {
    display: flex;
    .gallery-thumbs {
      img {
        width: 99px;
        display: block;
        margin-bottom: 10px;
      }
    }
    .gallery-image {
      width: 100%;
      text-align: center;
      padding: 0px 20px 0 20px;
      img {
        max-width: 100%;
      }
    }
  }
  .product-right {
    .params {
      border-left: 4px solid #0a3b5c;
      padding: 4px 0 4px 20px;
      margin-bottom: 35px;
      margin-top: 30px;
      @include media-breakpoint-down(sm) {
        margin-top: 30px;
      }
      p {
        font-size: 18px;
        line-height: 1.67;
        font-weight: 500;
        color: #000;
        margin: 0;
      }
    }
  }
  .product-description {
    border: 1px solid #dfdfdf;
    padding: 55px 60px;
    font-size: 16px;
    line-height: 1.38;
    font-weight: 500;
    margin-top: 29px;
    @include media-breakpoint-down(sm) {
      padding: 25px 30px;
    }
  }
}
