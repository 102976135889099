section.text {
  .single-content {
    .single-content-image {
      margin: 0 15px 15px 0;
    }
  }
  .term-description {
    padding: 0 15px;
    margin-top: 30px;
  }
}
