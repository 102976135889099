#inner-categories {
  padding-top: 0;
  .product {
    background: url(../../production/images/icon-menu-grey.svg) no-repeat;
    background-position: bottom 26px right 26px;
    margin: 0 0 30px 0;
    padding: 15px 37px 55px 37px;
    text-align: center;
    @include shadow();
    @include media-breakpoint-down(sm) {
    }
    @include media-breakpoint-down(xs) {
    }
  }
  img {
    margin-bottom: 30px;
    max-width: 100%;
    @include media-breakpoint-down(sm) {
    }
  }
  p {
    line-height: 1.25;
    font-size: 16px;
    opacity: 0.5;
    color: #000;
    font-weight: 500;
    @include media-breakpoint-down(xs) {
    }
  }
}
