@mixin verticalAlignCenter() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin shadow {
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.13);
  &:hover {
    box-shadow: 2px 2px 5px 0 rgba(10, 59, 92, 0.43);
  }
}

