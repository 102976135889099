.product.no_icon {
	background: none !important;
}
#catalog-categories {
  .product {
    background: url(../../production/images/icon-menu-grey.svg) no-repeat;
    background-position: bottom 26px right 26px;
    margin: 0 0 30px 0;
    padding: 32px 103px 50px 34px;
    @include shadow();
    min-height: 246px;
    @include media-breakpoint-down(sm) {
      padding: 32px 153px 0 34px;
    }
    @include media-breakpoint-down(xs) {
      padding: 17px 17px 0 17px;
    }
		&.no_icon{
			background: none;
		}
  }
  img {
    position: absolute;
    top: 63px;
    right: 22px;
    max-width: 136px;
    max-height: 178px;

    @include media-breakpoint-down(sm) {
      top: 63px;
      right: 22px;
      max-width: 160px;
      max-height: 160px;
    }
  }
  p {
    line-height: 1.25;
    font-size: 16px;
    opacity: 0.5;
    color: #000;
    padding-right: 40px;
    font-weight: 500;
    @include media-breakpoint-down(xs) {
      padding-right: 100px;
    }
  }
}
